<template>
  <div class="wrapper text-left" id="feature-compare" style="overflow-x:auto;">
    <h2 class="text-center">{{ $t("plans.feature_comparison") }}</h2>
    <table style="width: 99%;">
      <thead>
        <tr>
          <td>&nbsp;</td>
          <td>{{ $t("plans.basic") }}</td>
          <td>{{ $t("plans.premium") }}</td>
          <td>{{ $t("plans.business") }}</td>
        </tr>
      </thead>
      <tbody>
        <tr class="border">
          <td colspan="4">
            <!-- <h4>Data Capture</h4> -->
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_1") }}
          </td>
          <td class="one">2</td>
          <td class="two">5</td>
          <td class="three">10</td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_2") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_3") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_4") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_5") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_6") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_7") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_8") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_9") }}
          </td>
          <td class="one">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_10") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_11") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_12") }}
          </td>
          <td class="one">0</td>
          <td class="two">18</td>
          <td class="three">50</td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_13") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_14") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_15") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_16") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_17") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_18") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_19") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_20") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_21") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_22") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_23") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_24") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_25") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row">
          <td class="zero">
            {{ $t("plans.cards.features.text_26") }}
          </td>
          <td class="one"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="two"><img loading="lazy" alt="icon" width="15" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/times.png" /></td>
          <td class="three">
            <img loading="lazy" alt="icon" width="22" height="15" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/check.png" />
          </td>
        </tr>
        <tr class="comp-row" style="border: none;">
          <td class="zero"></td>
          <td
            class="select-plan"
            @click="$emit('selectPlan', 'basic', isAnnual)"
          >
            {{ $t("plans.cards.select_button") }}
          </td>
          <td
            class="select-plan"
            @click="$emit('selectPlan', 'normal', isAnnual)"
          >
            {{ $t("plans.cards.select_button") }}
          </td>
          <td
            class="select-plan"
            @click="$emit('selectPlan', 'premium', isAnnual)"
          >
            {{ $t("plans.cards.select_button") }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    isAnnual: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/master-postgrado/bootstrap.min.css";
h2 {
  color: #444;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.comp-row {
  height: 45px;
  font-size: 1.55rem;
  font-weight: 500;
  color: #444;
  border-bottom: 1px solid #e5e5e5;
}

td.zero {
  width: 40%;
  padding-right: 10%;
  text-align: left;
}

tr td {
  width: 20%;
  text-align: center;
}

tbody {
  background: #00909005;
}

thead {
  background: #0090901a;
  font-size: 1.2rem;
  font-weight: 600;

  tr {
    height: 45px;
  }
}

.select-plan {
  color: #009090;
  font-weight: 600;
  font-size: 1.4rem;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: #003838;
  }
}
</style>
