<template>
  <div style="margin-top: -20px;">
    <!-- <div> -->
    <div class="cover">
      <b-alert
        class="error-alert"
        show
        variant="danger"
        v-if="$route.query.payment === 'fail'"
      >
        {{ $t("plans.error_alert") }}
      </b-alert>
      <div class="rel-center">
        <h1 class="mobile" style="color: #fff">
          {{ $t("plans.title") }}
        </h1>
        <h3 style="color: #fff">{{ $t("plans.premium") }}</h3>
        <p class="p" style="text-align: center; color: #fff">
          {{ $t("plans.subtitle") }}
        </p>
        <div class="text-center select-plan">
          <div>
            <span
              class="switch-text"
              :class="showAnual ? 'nonactive' : ''"
              @click="showAnual = false"
            >
              {{ $t("plans.month_plans") }}
            </span>
            <label class="switch">
              <input
                type="checkbox"
                @click="showAnual = !showAnual"
                v-model="showAnual"
              />
              <span style="cursor:pointer;"></span>
            </label>
            <span
              class="switch-text"
              :class="showAnual ? '' : 'nonactive'"
              @click="showAnual = true"
            >
              {{ $t("plans.year_plans") }}
            </span>
            <div class="save-percent">
              <span
                >{{ $t("plans.save_up_to") }} 20%
                {{ $t("plans.with_annual") }}</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                viewBox="0 0 17.86 32.85"
              >
                <g
                  id="a003848f-9543-4cee-bfc4-b46e06fd4f70"
                  data-name="Layer 3"
                >
                  <path
                    d="M14.29,31.4a22.94,22.94,0,0,0-12.46-30"
                    style="fill: none;stroke: #fff;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 2px"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-container class="row" style=" z-index: 20; margin-top: -100px;">
      <b-col class="col">
        <div class="card">
          <div class="plan-name">{{ $t("plans.basic") }}<span>.</span></div>
          <p class="p" v-if="showAnual">
            {{ $t("plans.cards.membership") }} ({{ $t("plans.cards.annual") }})
          </p>
          <h4
            style="color: #999;text-decoration: line-through;"
            v-if="showAnual"
          >
            100€
          </h4>
          <h3 style="color: #1EA69A;" v-if="showAnual">89€</h3>
          <p class="p mb-custom-6" v-if="!showAnual">
            {{ $t("plans.cards.membership") }} ({{ $t("plans.cards.monthly") }})
          </p>
          <h3 style="color: #1EA69A; " v-if="!showAnual">100€</h3>
          <p class="p">{{ $t("plans.cards.per_month") }}</p>
          <a
            href="#"
            @click="selectPlan('basic', showAnual)"
            class="fancy-button bg-gradient2"
            ><span>{{ $t("plans.cards.select_button") }}</span></a
          >
          <hr />
          <br />
          <ul>
            <li class="li">
              {{ $t("plans.cards.features.text_1") }}:
              <strong>2</strong>
            </li>
            <li class="li">{{ $t("plans.cards.features.text_2") }}</li>
            <li class="li">{{ $t("plans.cards.features.text_3") }}</li>
          </ul>
          <div class="pb-5 compare-container" v-scroll-to="'#feature-compare'">
            <div class="btn-compare">{{ $t("plans.compare_plans") }}</div>
            <section id="section04" class="demo">
              <a href="#section05"><span></span></a>
            </section>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="card1">
          <div class="plan-name">{{ $t("plans.premium") }}<span>.</span></div>
          <p class="p" v-if="showAnual">
            {{ $t("plans.cards.membership") }} ({{ $t("plans.cards.annual") }})
          </p>
          <h4
            style="color: #999;text-decoration: line-through;font-size:2rem"
            v-if="showAnual"
          >
            160€
          </h4>
          <h2 style="color: #FFBE6A;" v-if="showAnual">149€</h2>
          <p class="p mb-custom-6" v-if="!showAnual">
            {{ $t("plans.cards.membership") }} ({{ $t("plans.cards.monthly") }})
          </p>
          <h2 style="color: #FFBE6A; " v-if="!showAnual">160€</h2>
          <p class="p">{{ $t("plans.cards.per_month") }}</p>
          <a
            href="#"
            @click="selectPlan('normal', showAnual)"
            class="fancy-button pop-onhover bg-gradient3"
            ><span>{{ $t("plans.cards.select_button") }}</span></a
          >
          <hr />
          <br />
          <ul>
            <li class="li">
              {{ $t("plans.cards.features.text_1") }}:
              <strong>5</strong>
            </li>
            <li class="li">{{ $t("plans.cards.features.text_2") }}</li>
            <li class="li">{{ $t("plans.cards.features.text_3") }}</li>
          </ul>
          <br />
          <div class="pb-5 compare-container" v-scroll-to="'#feature-compare'">
            <div class="btn-compare">{{ $t("plans.compare_plans") }}</div>
            <section id="section04" class="demo">
              <a href="#section05"><span></span></a>
            </section>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="card2">
          <div class="plan-name">{{ $t("plans.business") }}<span>.</span></div>
          <p class="p mb-1" v-if="showAnual">
            {{ $t("plans.cards.membership") }} ({{ $t("plans.cards.annual") }})
          </p>
          <h4
            style="color: #999;text-decoration: line-through;"
            v-if="showAnual"
          >
            400€
          </h4>
          <h3 style="color: #1EA69A;" v-if="showAnual">349€</h3>
          <p class="p mb-custom-6" v-if="!showAnual">
            {{ $t("plans.cards.membership") }} ({{ $t("plans.cards.monthly") }})
          </p>
          <h3 style="color: #1EA69A; " v-if="!showAnual">400€</h3>
          <p class="p">{{ $t("plans.cards.per_month") }}</p>
          <a
            href="#"
            @click="selectPlan('premium', showAnual)"
            class="fancy-button bg-gradient2"
            ><span>{{ $t("plans.cards.select_button") }}</span></a
          >
          <hr />
          <br />
          <ul>
            <li class="li">
              {{ $t("plans.cards.features.text_1") }}:
              <strong>10</strong>
            </li>
            <li class="li">{{ $t("plans.cards.features.text_2") }}</li>
            <li class="li">{{ $t("plans.cards.features.text_3") }}</li>
          </ul>
          <div class="pb-5 compare-container" v-scroll-to="'#feature-compare'">
            <div class="btn-compare">{{ $t("plans.compare_plans") }}</div>
            <section id="section04" class="demo">
              <a href="#section05"><span></span></a>
            </section>
          </div>
        </div>
      </b-col>
    </b-container>
    <b-container>
      <PlansDetails @selectPlan="selectPlan" :isAnnual="showAnual" />
    </b-container>
    <AcceptCookies />
  </div>
</template>

<script>
import PlansDetails from "../../components/spain/PlansDetails";
import AcceptCookies from "../../components/spain/AcceptCookies";

export default {
  name: "plans",
  data() {
    return {
      showAnual: false
    };
  },
  computed: {
    switchText: function() {
      return !this.showAnual ? "Anual" : "Mensual";
    }
  },
  components: {
    PlansDetails,
    AcceptCookies
  },
  methods: {
    selectPlan(name, isAnnual) {
      let obj = {
        price: null,
        name: null,
        isAnnual: null
      };
      if (name === "basic") {
        obj.price = isAnnual ? 1079 : 100;
        obj.name = "Basic";
        obj.isAnnual = isAnnual;
      } else if (name === "normal") {
        obj.price = isAnnual ? 1800 : 160;
        obj.name = "Normal";
        obj.isAnnual = isAnnual;
      } else if (name === "premium") {
        obj.price = isAnnual ? 4199 : 400;
        obj.name = "Premium";
        obj.isAnnual = isAnnual;
      }

      this.$router.push({ name: "plan-form", params: { obj: obj } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/master-postgrado/bootstrap.min.css";
@import "../../assets/css/master-postgrado/main.min.css";
$red: #48ea8b;
$green: #48ea8b;

.switch {
  input {
    display: none;
    & + span {
      width: 48px;
      height: 28px;
      border-radius: 14px;
      transition: all 0.3s ease;
      display: block;
      position: relative;
      background: $red;
      box-shadow: 0 8px 16px -1px rgba($red, 0.2);
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        transition: all 0.3s ease;
      }
      &:before {
        top: 5px;
        left: 7px;
        width: 1px;
        height: 18px;
        border-radius: 9px;
        border: 4px solid #fff;
      }
      &:after {
        top: 5px;
        left: 32px;
        width: 18px;
        height: 18px;
        border-radius: 40%;
        transform-origin: 50% 50%;
        background: #fff;
        opacity: 0;
      }
      &:active {
        transform: scale(0.92);
      }
    }
    &:checked {
      & + span {
        background: $green;
        box-shadow: 0 8px 16px -1px rgba($green, 0.2);
        &:before {
          margin-left: 27px;
        }
      }
    }
    &:not(:checked) {
      & + span {
        &:before {
          animation: blob 0.85s linear forwards 0.2s;
        }
      }
    }
  }
}

@keyframes blob {
  0%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.12, 0.94);
  }
  60% {
    transform: scale(0.96, 1.06);
  }
}

@keyframes blobChecked {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(1.44);
  }
  70% {
    transform: scaleX(1.18);
  }
  50%,
  99% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}

html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

// Center & dribbble
body {
  min-height: 100vh;
  font-family: Roboto, Arial;
  color: #adafb6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f9ff;
  .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
      display: block;
      height: 28px;
    }
  }
}
#style-15::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-15::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-15::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  );
}

@font-face {
  font-family: "DMSans";
  src: url("../../assets/fonts/DMSans-Bold.ttf");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("../../assets/fonts/DMSans-Regular.ttf");
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("../../assets/fonts/DMSans-Italic.ttf");
}

.cover {
  background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/hero-bg.jpg);
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: calc(400px + 100px);
  z-index: -1;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h2 {
  font-family: "DMSans", sans-serif;
  font-size: 70px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
}

h1,h3 {
  font-family: "DMSans", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
}

.p {
  font-family: "DMSans-Italic", sans-serif;
  color: #848484;
  font-size: 1.5rem;
}

ul {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  display: table;
  text-align: left;
  font-size: 1.5rem !important;
}

li::before {
  content: "•";
  color: forestgreen;
  margin: 10px;
}

.li {
  font-family: "DMSans-Regular", sans-serif;
  color: #a2a2a2;
  margin-bottom: 10px;
}

hr {
  width: 100%;
  display: flex;
  justify-content: center !important;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Float four columns side by side */
.column {
  width: 100%;
}

.col {
  flex-basis: 0;
  padding-right: 0px;
  padding-left: 0px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Remove extra left and right margins, due to padding in columns */
.row1 {
  margin: 200px 100px !important;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

/* Clear floats after the columns */
.row1:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 25px 15px !important;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 20px;
  /*width: 90%;*/
  height: auto;
  margin-right: -5px;
  // height: 460px;
}

.card1 {
  z-index: 2;
  position: relative;
  margin-top: -20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  /*width: 90%;*/
  height: auto;
  padding-bottom: 40px;
  // height: 500px;
}

.card2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 25px 15px !important;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 20px;
  /*width: 90%;*/
  height: auto;
  margin-left: -5px;
  // height: 460px;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 991px) {
  .col {
    margin: auto;
    /*width: 100%;*/
    /*display: flex;*/
  }

  .row {
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
  }

  .card,
  .card1,
  .card2 {
    border-radius: 20px !important;
    margin-bottom: 4rem;
  }

  .card1 {
    margin-bottom: 2rem !important;
  }

  .mobile {
    font-size: 1.5rem !important;
  }
}

/* Mixins */
@mixin gradientBg($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(
    left,
    $startColor 0%,
    $endColor 80%,
    $endColor 100%
  );
  background: -webkit-linear-gradient(
    left,
    $startColor 0%,
    $endColor 80%,
    $endColor 100%
  );
  background: linear-gradient(
    to right,
    $startColor 0%,
    $endColor 80%,
    $endColor 100%
  );
}

/* bg shortcodes */
.bg-gradient1 span,
.bg-gradient2 span,
.bg-gradient2:before {
  @include gradientBg(#3fc767, #33d8ba);
}

.bg-gradient3 span,
.bg-gradient3:before {
  @include gradientBg(#e2618f, #eacf39);
}

/* General */
.wrapper {
  margin: 5% auto;
  text-align: center;
  transform-style: perserve-3d;
  perspecive: 800px;
}

a {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

/* fancy Button */
.fancy-button {
  font-size: 1.4rem;
  display: inline-block;
  margin: 0px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;

  i {
    margin-top: -1px;
    margin-right: 20px;
    font-size: 1.265em;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    padding: 15px 45px;
    border-radius: 50em;
    position: relative;
    z-index: 2;
    will-change: transform, filter;
    transform-style: preserve-3d;
    transition: all 0.3s ease-out;
  }

  &:focus,
  &:active {
    color: #ffffff;
  }

  &:hover {
    color: #ffffff;

    span {
      filter: brightness(1.05) contrast(1.05);
      transform: scale(0.95);
    }

    &:before {
      bottom: 0;
      filter: blur(10px) brightness(0.95);
    }
  }

  &.pop-onhover {
    &:before {
      opacity: 0;
      bottom: 10px;
    }

    &:hover {
      &:before {
        bottom: -7px;
        opacity: 1;
        filter: blur(20px);
      }

      span {
        transform: scale(1.04);
      }

      &:active {
        span {
          filter: brightness(1) contrast(1);
          transform: scale(1);
          transition: all 0.15s ease-out;
        }

        &:before {
          bottom: 0;
          filter: blur(10px) brightness(0.95);
          transition: all 0.2s ease-out;
        }
      }
    }
  }
}

/*transitions*/
.bounce-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.bounce-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.bounce-enter-to,
.bounce-leave {
  max-height: 100px;
  overflow: hidden;
}

.bounce-enter,
.bounce-leave-to {
  overflow: hidden;
  max-height: 0;
}

/*arrow*/
$circle-w: 50px;
$circle-bg: #bbbbbb;
$arrow-bg: #ffffff;
$body-bg: #9ddcdc;
$bezier: cubic-bezier(0.77, 0, 0.175, 1);
$bezier-bounce: cubic-bezier(0.87, -0.41, 0.19, 1.44);
$anim-timing: 3s;

html,
        /*body {*/
        /*    height: 100%;*/
        /*    width: 100%;*/
        /*    text-align: center;*/
        /*}*/
    body {
  background-color: $body-bg;
}

.ico {
  margin: 0 auto;
  text-align: center;
  width: $circle-w;
  height: $circle-w;
  position: relative;
  /*top: 50%;*/
  transform: translateY(-50%);
  filter: url("#goo");
  cursor: pointer;

  .circle {
    background: $circle-bg;
    border-radius: 50%;
    display: inline-block;
    height: $circle-w;
    width: $circle-w;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: 0%;

    &.circle-top {
      height: $circle-w / 3;
      width: $circle-w / 2.4;
      animation: blob-1-anim $anim-timing $bezier infinite;
      z-index: 0;
      top: 0;
    }

    &.circle-bottom {
      height: $circle-w / 3;
      width: $circle-w / 2.4;
      animation: blob-2-anim $anim-timing $bezier infinite;
      z-index: 9;
      bottom: 0px;
    }
  }

  .st-arrow {
    fill: $arrow-bg;
    animation: scrolly $anim-timing $bezier forwards infinite;
    perspective: 9000px;
    transform: translateZ(0);
    transform-origin: bottom;
  }
}

.svg {
  z-index: 9;
  position: relative;
}

@keyframes blob-1-anim {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  14% {
    transform: translateX(-50%) translateY(-8px);
  }
  24% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes blob-2-anim {
  0% {
    //transform: scale(1) translate(-50%,-150%);
    transform: scale(1) translate(-50%, 10px);
  }
  30% {
    //transform: scale(1) translate(-50%,-50%);
    transform: scale(1) translate(-50%, 10px);
  }
  70% {
    //transform: scale(1) translate(-50%,-50%);
    transform: scale(1) translate(-50%, 10px);
  }
  95% {
    //transform: scale(1) translate(-50%,-50%);
    transform: scale(1) translate(-50%, 26px);
  }
  100% {
    //transform: scaleX(1.5) translate(-50%,140%);
    transform: scale(1) translate(-50%, 10px);
  }
}

@keyframes scrolly {
  0% {
    transform: translate3d(0, -150%, 0) rotateX(90deg) scale(0.5) skewX(3deg);
  }
  30% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
  }
  70% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
  }
  95% {
    transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5) skewX(-3deg);
  }
  100% {
    transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5) skewX(-3deg);
  }
}

.rel-center {
  position: relative;
  top: calc(50% - 25px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-alert {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  font-size: 1.8rem;
  text-align: center;
}

.switch-text {
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
  top: -13px;
  margin: 0 10px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;

  &.nonactive {
    color: #dadada;
    text-decoration: none;
  }
}

.select-plan {
  margin-top: 7rem;
}

.mb-custom-6 {
  margin-bottom: 4rem !important;
}

.save-percent {
  position: relative;
  bottom: 60px;
  left: 66px;

  span {
    position: relative;
    bottom: 10px;
    right: 10px;
    color: white;
    background: #009090;
    border-radius: 3px;
    padding: 3px 10px;
    font-size: 1.1rem;
  }
}

#section04 a {
  padding-top: 60px;
}
#section04 a span {
  position: absolute;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

.btn-compare {
  color: #999;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.compare-container {
  cursor: pointer;
}

.plan-name {
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 600;
  color: #484848;

  span {
    color: #009090;
  }
}
</style>
